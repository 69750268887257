/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/cloudinary_js@2.3.0/js/jquery.cloudinary.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/bootstrap@4.0.0-beta/dist/js/bootstrap.min.js
 * - /gh/adobe-webplatform/balance-text@3.3.1/balancetext.min.js
 * - /gh/jquery-backstretch/jquery-backstretch@2.1.17/src/jquery.backstretch.js
 * - /gh/imakewebthings/waypoints@latest/lib/jquery.waypoints.min.js
 * - /npm/mixitup@3.3.1/dist/mixitup.min.js
 * - /npm/jarallax@1.12.8/dist/jarallax.min.js
 * - /npm/jarallax@1.12.8/dist/jarallax-video.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
